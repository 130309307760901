<template>
    <v-card outlined elevation="0" flat class="border-0 py-5 px-3">
        <v-row>
            <v-col cols="12">
                <span class="font-size-20 primary--text">{{ $t('reporting.skills') }}</span></v-col
            >
            <v-col cols="12" class="pa-0 ml-auto">
                <BeeTabs
                    @changeTab="changeTab"
                    tab-color="#ff6f3a"
                    :items="days"
                    :tabSize="4"
                    textAlign="left"
                    :flat="false"
                    :disabled="loading"
                    tab-size="3"
                />
            </v-col>
        </v-row>
        <v-container>
            <v-row>
                <v-col cols="5">
                    <v-row class="d-flex justify-center align-center">
                        <v-col cols="6" class="d-flex justify-center align-center">
                            <EllipseProgress
                                :size="110"
                                :value="skill ? skill.localizar : 0"
                                color="#ff051b"
                            ></EllipseProgress>
                        </v-col>
                        <v-col cols="6" class="d-flex justify-center align-center">
                            <EllipseProgress
                                :size="110"
                                :value="skill ? skill.integrar : 0"
                                color="#6f55d0"
                            ></EllipseProgress>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center align-center">
                            <EllipseProgress
                                :size="110"
                                :value="skill ? skill.reflexionar : 0"
                                color="#1785e8"
                            ></EllipseProgress>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="7">
                    <v-row class="brown--text">
                        <v-col cols="2" class="d-flex justify-start align-start pa-0">
                            <v-img src="/img/ray/ray-1.svg" class="mx-3 mx-md-6" width="30"></v-img>
                        </v-col>
                        <v-col cols="9" class="d-flex justify-start flex-column align-start pa-0">
                            <span class="font-weight-bold">
                                {{ $t('reporting.abilityOne.title') }}
                            </span>
                            <p v-html="$t('reporting.abilityOne.desc', [getLevel(locData)])"></p>
                        </v-col>
                    </v-row>

                    <v-row class="brown--text">
                        <v-col cols="2" class="d-flex justify-start align-start pa-0">
                            <v-img src="/img/ray/ray-2.svg" class="mx-3 mx-md-6" width="30"></v-img>
                        </v-col>
                        <v-col cols="9" class="d-flex justify-start flex-column align-start pa-0">
                            <span class="font-weight-bold">
                                {{ $t('reporting.abilityTwo.title') }}
                            </span>
                            <p v-html="$t('reporting.abilityTwo.desc', [getLevel(inData)])"></p>
                        </v-col>
                    </v-row>

                    <v-row class="brown--text">
                        <v-col cols="2" class="d-flex justify-start align-start pa-0">
                            <v-img src="/img/ray/ray-3.svg" class="mx-3 mx-md-6" width="30"></v-img>
                        </v-col>
                        <v-col cols="9" class="d-flex justify-start flex-column align-start pa-0">
                            <span class="font-weight-bold">
                                {{ $t('reporting.abilityThree.title') }}
                            </span>
                            <p v-html="$t('reporting.abilityThree.desc', [getLevel(rvData)])"></p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import EllipseProgress from '../../core/EllipseProgress'
import BeeTabs from '../../core/BeeTabs'
export default {
    name: 'SkillsGraphics',
    components: { BeeTabs, EllipseProgress },
    props: {
        loading: { type: Boolean, default: false },
        inData: { type: [String, Number], default: 0 },
        rvData: { type: [String, Number], default: 0 },
        locData: { type: [String, Number], default: 0 },
        skillsData: { type: Array, default: () => [] },
    },
    data: () => ({ currentTab: 0 }),
    methods: {
        getLevel(progress) {
            if (progress <= 33) return this.$t('reporting.abilityLevels[0]')
            if (progress > 33 && progress <= 66) return this.$t('reporting.abilityLevels[1]')

            return this.$t('reporting.abilityLevels[2]')
        },
        changeTab(tab) {
            this.currentTab = tab
        },
    },
    computed: {
        days() {
            return [
                this.$t('stats.four_week'),
                this.$t('stats.eigth_weeks'),
                this.$t('stats.twelve_weeks'),
            ]
        },
        skill() {
            return this.skillsData.find((i) => i.index === this.currentTab)
        },
    },
}
</script>

<style scoped></style>
