import Vue from 'vue'
const authorMixin = Vue.extend({
    computed: {
        author() {
            const _this: any = this
            if (!_this.book) return _this.$t('anonymous')

            const { bookAuthorDTOList = [], authorDTO } = _this.book

            return _this.$utils.getAuthorName(authorDTO, bookAuthorDTOList, _this.$t('anonymous'))
        },
    },
})

export default authorMixin
