<template>
    <div class="d-flex justify-start align-start my-2">
        <div class="indicator" :style="{ backgroundColor: color }"></div>
        <span class="primary--text font-weight-bold mx-2">{{ titleText }}</span>
        <span class="primary--text text-subtitle-1">{{ infText }}</span>
    </div>
</template>

<script>
export default {
    name: 'BadgeIndicator',
    props: {
        color: { type: String, default: '#000', required: true },
        titleText: { type: String, default: 'Title', requiered: true },
        infText: { type: String, default: 'Information', requiered: true },
    },
}
</script>

<style scoped>
.indicator {
    height: 3vh;
    width: 1vh;
    border-radius: 0 .5vh .5vh 0;
}
</style>
