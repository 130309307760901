<template>
    <v-row cols="12">
        <v-card
            elevation="5"
            class="pb-15 w-100 border-radius-1 elevation-1"
            outlined
            :loading="loading"
        >
            <v-row>
                <v-col class="d-flex justify-center align-center pa-0 py-1 mt-3" cols="2">
                    <v-btn tile color="primary" text class="text-capitalize" @click="$router.go(-1)"
                        ><v-icon class="mr-2">mdi-arrow-top-left</v-icon>{{ $t('go_back') }}</v-btn
                    >
                </v-col>
            </v-row>
            <v-row class="px-7">
                <v-col cols="12">
                    <v-row class="px-0 justify-center align-center">
                        <v-col cols="12" class="pa-0">
                            <v-card-title class="text-h6 font-weight-bold primary--text py-1">{{
                                $t('reporting.top_student_performence_data')
                            }}</v-card-title>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="px-8" v-if="dataLoaded">
                <v-col cols="3">
                    <FullStudentDetail
                        :reader="userInfo.user"
                        :reader-data="userInfo"
                        :accuracy="accuracy"
                    ></FullStudentDetail>
                </v-col>
                <v-col cols="9">
                    <v-row class="pa-0">
                        <v-col cols="12">
                            <SkillsGraphics :skills-data="evolutionData.skills"></SkillsGraphics>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12">
                            <CurrentBookReading
                                :book="userInfo.book"
                                :correct-answer-percentage="10"
                                :assign="userInfo.assigment"
                                :questions-data="currentReadingData.questionsDTO.students"
                                :total-questions="currentReadingData.questionsDTO.totalQuestions"
                                :hours-data="
                                    currentReadingData.readingHoursDTO.readingHoursByStudent
                                "
                                :total-hours="currentReadingData.readingHoursDTO.totalHoursRead"
                                :page-read-data="currentReadingData.pagesReadDTO.pagesReadByStudent"
                                :total-pages-read="currentReadingData.pagesReadDTO.totalPagesRead"
                            ></CurrentBookReading>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-row>
</template>

<script>
import courseBookStats from '../../../services/courseBookStats'
import BeeTabs from '../../core/BeeTabs'
import FullStudentDetail from '../core/FullStudentDetail'
import SkillsGraphics from '../core/SkillsGraphics'
import CurrentBookReading from '../core/CurrentBookReading'

export default {
    name: 'StudentPerformanceData',
    props: {
        userId: { type: [Number, String], default: 0 },
        accuracy: { type: [Number, String], default: 0 },
    },
    components: {
        CurrentBookReading,
        SkillsGraphics,
        FullStudentDetail,
        BeeTabs,
    },
    async mounted() {
        this.loading = true
        const response = await courseBookStats
            .getFullUserData(this.userId)
            .catch((e) => console.error(e))
        const { initData, evolution, courseBookStat } = response.content
        this.userInfo = initData
        this.evolutionData = evolution
        this.currentReadingData = courseBookStat
        this.dataLoaded = true
        this.loading = false
    },
    data: () => ({
        currentClass: null,
        dataLoaded: false,
        currentTab: 0,
        loading: false,
        tableData: [],
        fullData: null,
        evolutionData: null,
        userInfo: null,
        currentReadingData: null,
    }),
    computed: {
        days() {
            return [
                this.$t('stats.four_week'),
                this.$t('stats.eigth_weeks'),
                this.$t('stats.twelve_weeks'),
            ]
        },
    },
    methods: {
        changeTab(tab) {
            this.currentTab = tab
            const { day7, day30, day90 } = this.fullData
            switch (this.currentTab) {
                case 0:
                    this.tableData = day7
                    break
                case 1:
                    this.tableData = day30
                    break
                case 2:
                    this.tableData = day90
                    break
            }
        },
        filterArrayValue(arr = [], valueFrom = 0, valueTo = 100) {
            return arr.filter((i) => i.accuracy >= valueFrom && i.accuracy <= valueTo)
        },
    },
}
</script>

<style scoped></style>
