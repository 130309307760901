<template>
    <v-card outlined elevation="0" flat class="border-0 py-5 px-3">
        <v-row>
            <v-col cols="12">
                <span class="font-size-24 primary--text">{{
                    $t('reporting.currently_reading')
                }}</span></v-col
            >
        </v-row>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="2">
                            <v-img
                                :src="`https://assets.beereaders.com/lbr/image/${book.resourceDir}/cover_small.jpg?v=${new Date().getTime()}`"
                                width="125"
                                height="175"
                                class="border-radius-0"
                                v-if="book"
                            ></v-img>
                            <v-skeleton-loader
                                type="image"
                                width="275"
                                height="350"
                                v-else
                            ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="5">
                            <v-container
                                class="d-flex justify-start align-start flex-column h-100"
                                v-if="book"
                            >
                                <span class="font-size-28 primary--text">{{ book.title }}</span>
                                <span class="font-size-20 grey--text lighten-2">{{ author }}</span>
                            </v-container>
                            <v-skeleton-loader
                                v-else
                                type="list-item-three-line, card-heading, list-item-two-line"
                            ></v-skeleton-loader>
                        </v-col>
                        <v-col cols="4" class="ml-auto">
                            <v-container>
                                <v-card
                                    class="d-flex justify-start align-start flex-column skills-card pa-0 py-4"
                                    elevation="0"
                                    rounded
                                >
                                    <div v-if="book">
                                        <span
                                            class="primary--text font-size-20 font-weight-bold mx-2"
                                            >{{ $t('library.assignment_date') }}</span
                                        >
                                        <BadgeIndicator
                                            color="#24dca9"
                                            :title-text="`${$t('start')}:`"
                                            :inf-text="
                                                $moment(assign.startingDate).format('MMM DD, YYYY')
                                            "
                                        />
                                        <BadgeIndicator
                                            color="#6F55D0"
                                            :title-text="`${$t('end')}:`"
                                            :inf-text="
                                                $moment(assign.evaluationDate).format(
                                                    'MMM DD, YYYY'
                                                )
                                            "
                                        />
                                    </div>
                                    <v-skeleton-loader
                                        type="list-item-three-line"
                                        height="100"
                                        width="100"
                                        v-else
                                    ></v-skeleton-loader>
                                </v-card>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider class="my-2 w-100"></v-divider>
                <span class="my-3 font-size-24 primary--text">{{
                    $t('quick_stats_link')
                }}</span>
                <v-col cols="12" class="w-100 d-flex justify-center align-center flex-column mt-4">
                    <div class="w-100 d-flex justify-space-around align-center">
                        <div class="w-25">
                            <CardStats
                                chart-id="page-read"
                                card-id="card-page-read"
                                :title="$t('library.total_page_read')"
                                :data="mapCountPercent(pageReadData, false)"
                                :number="totalPagesRead"
                                percent
                                line-color="#25e1d3"
                                icon="/img/teacher/quick-stats/reads.svg"
                            />
                        </div>
                        <div class="w-25">
                            <CardStats
                                chart-id="reading-hours"
                                card-id="card-reading-hours"
                                :title="$t('library.reading_hours')"
                                :data="mapCountPercent(hoursData, false)"
                                :number="totalHours"
                                line-color="#56cee4"
                                icon="/img/teacher/quick-stats/reading-hours.svg"
                            />
                        </div>
                        <div class="w-25">
                            <CardStats
                                chart-id="questions"
                                card-id="card-questions"
                                :title="$t('library.questions')"
                                :data="questionsData"
                                :number="totalQuestions"
                                line-color="#f9bf45"
                                icon="/img/teacher/quick-stats/avg-quiz-score.svg"
                            />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import BeeTabs from '../../core/BeeTabs'
import BadgeIndicator from '../../library/core/BadgeIndicator'
import CardStats from '../../core/CardStats'

import Author from '../../../mixins/author'

export default {
    name: 'CurrentBookReading',
    components: { CardStats, BadgeIndicator, BeeTabs },
    props: {
        book: { type: Object, default: () => null },
        correctAnswerPercentage: { type: [String, Number], default: 0 },
        assign: { type: Object, default: () => null },
        pageReadData: { type: Array, default: () => [] },
        totalQuestions: { type: [String, Number], default: 0 },
        totalPagesRead: { type: [String, Number], default: 0 },
        totalHours: { type: [String, Number], default: 0 },
        questionsData: { type: Array, default: () => [] },
        hoursData: { type: Array, default: () => [] },
    },
    methods: {
        mapCountPercent(array, isCount, custom = false) {
            let result = []
            if (isCount) {
                array.forEach((element) => result.push(element.count))
            }
            if (custom) {
                array.forEach((element) => result.push(element.correctAnswers))
            } else {
                array.forEach((element) => result.push(element.percent))
            }
            return result
        },
    },
    mixins: [Author],
}
</script>

<style scoped>
.skills-card {
    box-sizing: border-box;
    border: 2px solid #e9e6f0 !important;
    border-radius: 6px !important;
}
</style>
