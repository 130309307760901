import Services from './base-services'
import mapParameters from '../utils/map-parameters'

const service = new Services('courseBookStats')

export default {
    getData(courseId: [number, string], bookId: [number, string]) {
        return service.get(`getData?courseId=${courseId}&bookId=${bookId}`)
    },
    getFullAccuracyData(courseId: [number, string], teacherId: [number, string]) {
        return service.get(`getFullAccuracyData?courseId=${courseId}&teacherId=${teacherId}`)
    },
    getBasicUserData(userId: [number, string]) {
        return service.get(`getBasicUserData?userId=${userId}`)
    },
    getFullUserData(userId: [number, string]) {
        return service.get(`getFullUserData?userId=${userId}`, undefined, true)
    },
    getAccuracyDataByDateRange(courseId: [number, string], from: string, to: string) {
        return service.get(`getAccuracyDataByDateRange?courseId=${courseId}&from=${from}&to=${to}`)
    },
    getSummaryAccuracyDataByDateRangeAndCourses(teacherId: [number, string], from: string, to: string) {
        return service.get(`getSummaryAccuracyDataByDateRangeAndCourses?teacherId=${teacherId}&from=${from}&to=${to}`)
    },
    getDataByEvaluationDate(
        courseId: [number, string],
        bookId: [number, string],
        courseBookId: [number, string],
        date: string,
    ) {
        const params = mapParameters({
            courseId: courseId ? courseId : null,
            bookId: bookId ? bookId : null,
            courseBookId: courseBookId ? courseBookId : null,
            date: date ? date : null,
        })
        return service.get('getDataByEvaluationDate', params)
    },
}
