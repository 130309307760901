<template>
    <v-card
        outlined
        elevation="0"
        width="250"
        class="student-detail-card position-relative d-flex flex-column align-center justify-center py-7"
        :loading="loading"
        v-if="reader"
    >
        <v-container class="py-0" v-if="readerData">
            <v-row
                ><v-col cols="12" class="d-flex justify-center align-center">
                    <UserAvatar
                        :user-name="$utils.getFullName(reader)"
                        :avatar="reader.avatar"
                        size="25"
                        class="avatar-position"
                        font-size="48px"
                        v-if="readerData"
                    />
                </v-col>
                <v-col
                    cols="12"
                    class="text-center pb-1 pt-8 d-flex primary--text justify-center align-center"
                >
                    <span class="text-center pa-0 font-size-18">
                        {{ $utils.getFullName(reader) }}</span
                    >
                </v-col>
            </v-row>
            <v-row class="px-3 py-0" align="center" justify="center">
                <v-col cols="6" class="text-center primary--text">
                    <span class="primary--text text font-size-14 text-center">{{
                        readerData.user.course.name
                    }}</span>
                </v-col>
                <v-col cols="6" class="text-center primary--text">
                    <span class="primary--text font-size-14 text-center text-capitalize">{{
                        `${$t('level')} ${readerData.userGameStats.userLevel}`
                    }}</span>
                </v-col>
            </v-row>
            <v-divider color="#9d95bb"></v-divider>
            <v-container class="py-0">
                <v-row class="pa-0">
                    <v-col
                        cols="8"
                        class="d-flex justify-start align-start flex-column primary--text"
                    >
                        <span class="font-size-14">{{ $t('reporting.reading_time') }}</span>
                        <span class="primary--text font-size-14 text-body-2 font-weight-light"
                            >{{ readerData.hoursReads }}h</span
                        >
                    </v-col>
                    <v-col cols="4" class="d-flex justify-start align-start flex-column">
                        <span class="primary--text font-size-14">{{ $t('reporting.reads') }}</span>
                        <span class="primary--text font-size-14 text-body-2 font-weight-light">{{
                            readerData.booksReadsCounter
                        }}</span>
                    </v-col>
                </v-row>
                <v-row class="pa-0">
                    <v-col cols="12" class="d-flex justify-start align-start flex-column">
                        <span class="primary--text font-size-16">{{
                            $t('reporting.currently_reading')
                        }}</span>
                        <span class="primary--text font-size-12 text-body-1">{{
                            readerData.book.title
                        }}</span>
                    </v-col>
                </v-row>
                <v-row class="pa-0">
                    <v-col cols="12" class="d-flex justify-start align-start flex-column">
                        <span class="primary--text font-size-16">{{
                            $t('reporting.compared_performance')
                        }}</span>
                        <span class="primary--text font-size-12 text-body-1">{{
                            perfomance_msg
                        }}</span>
                    </v-col>
                    <v-col cols="12">
                        <SkillBar
                            :color="color(readerData.performance)"
                            :value="readerData.performance"
                            title-color="#514773"
                            :title="$t('reporting.reading_comprehension')"
                        />
                    </v-col>
                    <v-divider></v-divider>
                    <v-col cols="12">
                        <span class="primary--text font-size-16">{{
                            $t('reporting.tier_group')
                        }}</span>
                        <div
                            :class="`py-1 my-3 text-center font-size-12 w-40`"
                            :style="{
                                backgroundColor: color(readerData.performance),
                                color: 'white',
                            }"
                        >
                            {{ range(readerData.performance) }}
                        </div>
                    </v-col>
                    <v-btn outlined large color="secondary" class="mx-auto mt-7">{{ $t('resources.send_message') }}</v-btn>
                </v-row>
            </v-container>
        </v-container>
    </v-card>
</template>

<script>
import UserAvatar from '../../core/UserAvatar'
import SkillBar from '../../core/SkillBar'
export default {
    name: 'FullStudentDetail.vue',
    components: { SkillBar, UserAvatar },
    props: {
        reader: { type: Object, default: () => null },
        readerData: { type: Object, default: () => null },
        userId: { type: [String, Number], default: '' },
        accuracy: { type: Number, default: 0 },
        accuracyData: { type: Array, default: () => [] },
    },
    async mounted() {
        this.percently = this.$utils.percentRank(
            [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            this.accuracy
        )

        this.loading = false
    },
    data: () => ({
        loading: false,
        percently: 0,
    }),
    computed: {
        perfomance_msg() {
            return this.$t('reporting.performance_msg', [
                this.$utils.upperFirstLetter(this.reader.firstname),
                this.percently,
            ])
        },
    },
    methods: {
        color(value = 0) {
            if (value >= 61) return '#24dca9'
            if (value >= 41 && value <= 60) return '#fddc61'

            return '#514773'
        },
        range(value = 0) {
            if (value >= 61) return '61% - 100%'
            if (value >= 41 && value <= 60) return '41% - 60%'

            return '0% - 40%'
        },
    },
}
</script>

<style scoped>
.student-detail-card {
    border: 2px solid #bcb4d7 !important;
    background: #faf9ff;
}
</style>
